import React, { useEffect, useRef, useState } from 'react';
import start_static from './img/startscreen_static.png';
import start_gif from './img/startscreen_animated.gif';

import pre_1 from './img/startscreen_static.png';
import pre_2 from './img/startscreen_animated.gif';
import pre_3 from './img/volume.svg';
import pre_4 from './img/mute.svg';
import pre_5 from './img/fullscreen.svg';
import pre_6 from './img/logo.svg';
import pre_7 from './img/menu.svg';
import pre_8 from './img/menu_close.svg';
import pre_9 from './img/about.png';
import pre_10 from './img/shop.png';
import pre_11 from './img/program.png';
import pre_12 from './img/loadGif.gif';
import cloud from './img/cloud.svg';
import at from './img/at.svg';
import yt from './img/youtube.svg';
import insta from './img/insta.svg';

function Welcome(props) {
    const [showAnimated, setShowAnimated] = useState(false);
    const gifRef = useRef();
    const staticRef = useRef();

    useEffect(() => {
        const imgs = [
            pre_1, pre_2, pre_3, pre_4, pre_4, pre_5, pre_6, pre_7, pre_8, pre_9, pre_10, pre_11, pre_12,
            cloud, at, yt, insta
        ]
        cacheImages(imgs);
    }, [])

    const cacheImages = async (srcArray) => {
        const promises = await srcArray.map((src) => {
            return new Promise(function (resolve, reject) {
                const img = new Image();
                img.src = src;
                img.onload = resolve();
                img.onerror = reject();
            })
        })

        await Promise.all(promises);
    }

    useEffect(() => {
        if (showAnimated || props.mobile) {
            staticRef.current.classList.add("displayNone");
            gifRef.current.classList.remove("displayNone");
            return
        }

        if (!showAnimated) {
            staticRef.current.classList.remove("displayNone");
            gifRef.current.classList.add("displayNone");
            return
        }        
    }, [showAnimated, props.mobile])

    return <div className="welcome">
        <img
            ref={gifRef}
            onClick={() => props.onEnter(true)}
            onMouseOut={() => setShowAnimated(false)}
            className="pointer displayNone welcome-img welcome-img-animated"
            src={start_gif} alt="logo - click me">
        </img>
        <img ref={staticRef} onMouseOver={() => setShowAnimated(true)}
            className="pointer displayNone welcome-img welcome-img-static" src={start_static} alt="logo - hover me">
        </img>

    </div>;
}

export default Welcome;