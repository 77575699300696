import React, { useEffect, useRef } from 'react';
import program from './img/program.png';
import about from './img/about.png';
import shop from './img/shop.png';

const ContentPane = (props) => {
    const aboutHeader = useRef();
    const shopHeader = useRef();
    const programmHeader = useRef();

    useEffect(() => {
        switch(props.content) {
            case 'PROGRAM':
                programmHeader.current.classList.remove("displayNone")
                aboutHeader.current.classList.add("displayNone")
                shopHeader.current.classList.add("displayNone")
                break
            case 'ABOUT':
                programmHeader.current.classList.add("displayNone")
                aboutHeader.current.classList.remove("displayNone")
                shopHeader.current.classList.add("displayNone")
                break
            case 'SHOP':
                programmHeader.current.classList.add("displayNone")
                aboutHeader.current.classList.add("displayNone")
                shopHeader.current.classList.remove("displayNone")
                break
            default:
        }
    }, [props.content])

    return (
        <div className="contentPane">
            {props.content === "ABOUT"
                ? aboutContent()
                : (<p className="contentParagraph">SOON.</p>)
            }
            <div className="contentHeader_container">
                <img ref={programmHeader} src={program} alt="PROGAM" className="displayNone"></img>
                <img ref={aboutHeader} src={about} alt="ABOUT" className="displayNone"></img>
                <img ref={shopHeader} src={shop} alt="SHOP" className="displayNone"></img>
            </div>

        </div>
    )

    function aboutContent() {
        if (props.mobile) {
            return <p className="contentParagraph">
                WVTV IS A MIX OF<br></br>
                EXPERIMENTAL DJ SETS<br></br>
                AND ARTISTIC VISUALS.<br></br>
                NO PAUSE, NO PLAY,<br></br>
                NO SKIP BUTTON.<br></br>
                WVTV IS LIKE<br></br>
                WATCHING TELEVISION<br></br>
                IN THE 90’S<br></br>
                BUT ON THE INTERNET.<br></br>
                ‘WV’ STANDS FOR<br></br>
                ‘WIEVIEL’ (HOW MUCH).<br></br>
                WVTV IS AN EXPERIMENT<br></br>
                BY NATHAN GALANTE<br></br>
                & MAX APPEL.
            </p>
        }
        return <p className="contentParagraph">
            WVTV IS A MIX OF EXPERIMENTAL DJ SETS<br></br>
            AND ARTISTIC VISUALS.<br></br>
            NO PAUSE, NO PLAY, NO SKIP BUTTON.<br></br>
            WVTV IS LIKE WATCHING TELEVISION<br></br>
            IN THE 90’S BUT ON THE INTERNET.<br></br>
            ‘WV’ STANDS FOR ‘WIEVIEL’ (HOW MUCH).<br></br>
            WVTV IS AN EXPERIMENT<br></br>
            BY NATHAN GALANTE & MAX APPEL.
        </p>
    }
}

export default ContentPane;