import React, { useEffect, useRef, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import Video from './Video';
import Menu from './Menu';
import fs from './fullscreen';

const Main = (props) => {
  const fullScreenContainer = useRef()
  const [muted, setMuted] = useState(props.muted)
  const [fullscreen, setFullscreen] = useState(props.fullscreen)
  const [open, setOpen] = useState(props.open)

  useEffect(() => {
    if (fullscreen) {
      fs.openFullscreen();
      return
    }
    fs.closeFullscreen();
  }, [fullscreen])

  return (
    <div ref={fullScreenContainer} className="main">
      <Header
        open={open}
        setOpen={(val) => { setOpen(val) }}
        openWelcome={props.openWelcome}>
      </Header>
      {open &&
        <Menu showMenu={open} mobile={props.mobile}></Menu>
      }
      <Footer
        mobile={props.mobile}
        muted={muted} mute={(val) => { setMuted(val) }}
        toggleFullscreen={() => { setFullscreen(!fullscreen) }}>
      </Footer>
      <Video
        entered={props.entered}
        muted={muted}>
      </Video>
    </div>
  )
}

export default Main;