import React, { useEffect, useRef, useState } from 'react';
import video from './video/placeholder.mp4';
import loadGif from './img/loadGif.gif';

const Video = (props) => {
    const videoRef = useRef();
    const loadRef = useRef();
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        videoRef.current.muted = props.muted;
    }, [props.muted])

    useEffect(() => {
        if (duration > 0 && props.entered) {
            var now = new Date();
            var time = now.getMinutes() * 60 + now.getSeconds();
            videoRef.current.currentTime = time;
            videoRef.current.play();
        }
    }, [duration, props.entered])


    function onLoadedMetadata() {
        setDuration(videoRef.current.duration);
    }

    return (
        <div className="videoContainer">
            {duration === 0 &&
                <div ref={loadRef} className="loadingContainer">
                <img src={loadGif} alt="Loading..."></img>
            </div>
            }
            <video onLoadedMetadata={onLoadedMetadata} ref={videoRef} id="mainVideo" loop playsInline>
                <source src={video} type="video/mp4"></source>
            </video>
        </div>
    )
}

export default Video;