import React, { useEffect, useRef } from 'react';
import logo from './img/logo.svg';
import menu_open from './img/menu.svg';
import menu_close from './img/menu_close.svg';

const Header = (props) => {
    const openMenu = useRef();
    const closeMenu = useRef();

    useEffect(() => {
        if (props.open) {
            openMenu.current.classList.add("displayNone");
            closeMenu.current.classList.remove("displayNone");
            return
        }
        openMenu.current.classList.remove("displayNone");
        closeMenu.current.classList.add("displayNone");
    }, [props.open])

    return (
        <div className="headerFooterContainer header">
            <img onClick={() => props.openWelcome()} src={logo} alt="wvtv logo" className="headerImg pointer"></img>
            <img ref={openMenu} onClick={() => props.setOpen(true)} src={menu_open} alt="Open Menu" className="menuImg pointer"></img>
            <img ref={closeMenu} onClick={() => props.setOpen(false)} src={menu_close} alt="Close Menu" className="menuImg pointer displayNone"></img>
        </div>
    )
}

export default Header;