import { useState } from 'react';
import Main from './Main';
import Welcome from './Welcome'
import useIsMobile from './useIsMobile.js';
import fs from './fullscreen';

function App() {
  const [entered, setEntered] = useState(false);
  const [muted, setMuted] = useState(false)
  const [open, setOpen] = useState(false)
  const [fullscreen, setFullscreen] = useState(false)
  const isMobile = useIsMobile().isMobile;

  function openWelcome() {
    fs.closeFullscreen();
    setFullscreen(false)
    setMuted(false)
    setOpen(false)
    setEntered(false)
  }

  return (
    <div className="App">
      {entered
        ? <Main
          mobile={isMobile}
          entered={entered}
          muted={muted}
          fullscreen={fullscreen}
          open={open}
          openWelcome={openWelcome}
        ></Main>
        : <Welcome
          mobile={isMobile}
          onEnter={setEntered}>
        </Welcome>
      }
    </div>
  );
}

export default App;
