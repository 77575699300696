import React, { useState } from 'react';
import './Menu.css';
import cloud from './img/cloud.svg';
import at from './img/at.svg';
import yt from './img/youtube.svg';
import insta from './img/insta.svg';
import ContentPane from './ContentPane';


const Menu = (props) => {
    const [content, setContent] = useState("");
    const [showMenu, setShowMenue] = useState(props.showMenu);

    return (
        <div className="menuContainer">
            {showMenu
            ?    <div className="menu">
                    <p onClick={() => {
                        setContent("PROGRAM"); 
                        setShowMenue(false)}} 
                        className="pointer">PROGRAM
                    </p>
                    <p onClick={() => {
                        setContent("ABOUT"); 
                        setShowMenue(false)}}  
                        className="pointer">ABOUT
                    </p>
                    <p onClick={() => {
                        setContent("SHOP"); 
                        setShowMenue(false)}}  
                        className="pointer last">SHOP
                    </p>
                    <Links></Links>
                </div>
            :<ContentPane mobile={props.mobile} content={content}></ContentPane>
            }
        </div>
    )
}

const Links = () => {
    return (
        <div className="menuImgContainer">
        <a target="_blank" href="https://soundcloud.com/wvtv">
            <img src={cloud} alt="Sound Cloud" className="pointer"></img>
        </a>
        <a target="_blank" href="mailto:hello@wv-tv.com">
        <img src={at} alt="Mail" className="pointer"></img>
        </a>
        <a target="_blank" href="https://www.youtube.com/@wievieltv">
        <img src={yt} alt="Youtube" className="pointer"></img>
        </a>
        <a target="_blank" href="https://www.instagram.com/wievieltv/">
        <img src={insta} alt="Instagram" className="pointer"></img>
        </a>
    </div>
    )
}

export default Menu;