import React, { useState } from 'react';
import volume from './img/volume.svg';
import mute from './img/mute.svg';
import fullscreen from './img/fullscreen.svg';

const Footer = (props) => {
    const [muted, setMuted] = useState(props.muted)

    function doMute(bool) {
        setMuted(bool);
        props.mute(bool);
    }

    return (
        <div className="headerFooterContainer footer">
            {!muted && <img onClick={() => doMute(true)} src={volume} alt="Mute" className="menuImg pointer"></img>}
            {muted && <img onClick={() => doMute(false)} src={mute} alt="Unmute" className="menuImg pointer"></img>}
            {!props.mobile && <img onClick={() => props.toggleFullscreen()} src={fullscreen} alt="Fullscreen" className="menuImg pointer"></img>}
        </div>
    )
}

export default Footer;